import React,  { useRef,forwardRef, useImperativeHandle, useState } from "react";
import { useCookies } from 'react-cookie';
import * as Mui  from '@mui/material';
import * as MuiBase  from '@mui/base';
import * as MuiIcon from '@mui/icons-material';
import * as MuiJoy from '@mui/joy';
import { MuiFileInput } from 'mui-file-input';
import { ColorPicker } from '@wellbees/color-picker-input'
import MsgBox from '../lib/MsgBox';
import profileIcon from '../Assets/Images/profile-icon.png'
import { useForm, SubmitHandler } from "react-hook-form"


const DrawerSettings = forwardRef((props,ref) => {
    const [cookie,setCookie,updateCookies,removeCookie] = useCookies(['bas_']);
    const formRef = useRef();
    const themeRef = useRef();
    const chpformRef = useRef();
    const [loading,setLoading] = React.useState(false)
    const [loadingUpload,setLoadingUpload] = React.useState(false)
    const [fileLogo, setFileLogo] = React.useState(null)
    const [photoUpload, setPhotoUpload] = React.useState(null)
    const [filePhotos, setFilePhotos] = React.useState([])
    const [loadingchp,setLoadingchp] = React.useState(false)
    const [taxo,setTaxo] = React.useState([])
    const [error,setError] = React.useState([])
    const [isMsgBox, setisMsgBox] = React.useState({
      open: false,
      status: 'ok',
      title:'',
      msg: '',
      action:''
    });
    const { register, setValue, getValues, resetField, handleSubmit,reset } = useForm()

    const [theme,setTheme] = useState(0)
    const [bgColor, setBgColor] = React.useState('')
    const [borderColor, setBorderColor] = React.useState('')
    const [formColor, setFormColor] = React.useState('')
    const [textColor, setTextColor] = React.useState('')
    const [buttonColor, setButtonColor] = React.useState('')
    const [buttonTextColor, setButtonTextColor] = React.useState('')

    const handleChange = (newValue) => {
      setBgColor(newValue)
    }

  
    const onSubmit = (data) => {
      loadingOn()
        
      const myHeaders = new Headers();
      myHeaders.append("Authorization",cookie.token);

      const formData = new FormData();
      formData.append("company_name", getValues('company_name'))
      formData.append("title", getValues('title'))
      formData.append("description", getValues('description'))
      formData.append("format_wa", getValues('format_wa'))
      formData.append("user", getValues('user'))
      formData.append("password", getValues('password'))
      formData.append("background_color", bgColor)
      formData.append("border_color", borderColor)
      formData.append("form_color", formColor)
      formData.append("text_color", textColor)
      formData.append("button_color", buttonColor)
      formData.append("button_text_color", buttonTextColor)
      formData.append("fill_color", '#cccccc')
      formData.append("theme", theme)
      if(fileLogo){formData.append("company_logo", fileLogo, fileLogo.name)}

      // Display the key/value pairs
      //for (var pair of formData.entries()) {
      //  console.log(pair[0]+ ', ' + pair[1]); 
      //}
      const requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: formData,
        redirect: "follow"
      };


      fetch(props.config.hostApi + 'portal/setting/update', requestOptions)
          .then((response) => response.json())
          .then((result) => {

            if(result.success){
              //setting
              setCookie('company_logo', result.data.company_logo);
              setCookie('company_name', result.data.company_name);
              setCookie('title', result.data.title);
              setCookie('description', result.data.description);
              setCookie('format_wa', result.data.format_wa);
              
              setCookie('hotspotuser', result.data.user);
              setCookie('hotspotpass', result.data.password);

              setCookie('theme', result.data.theme);
              setCookie('background_color', result.data.background_color);
              setCookie('border_color', result.data.border_color);
              setCookie('form_color', result.data.form_color);
              setCookie('button_color', result.data.button_color);
              setCookie('text_color', result.data.text_color);
              setCookie('button_text_color', result.data.button_text_color);

              setValue('user',result.data.user)
              setValue('password',result.data.password)

              setTheme(result.data.theme)
              setBgColor(result.data.background_color )
              setBorderColor(result.data.border_color)
              setFormColor(result.data.form_color)
              setTextColor(result.data.text_color)
              setButtonColor(result.data.button_color)
              setButtonTextColor(result.data.button_text_color)

              setCookie('setting',true)
              setValue('company_name',cookie.company_name)
              setValue('title',cookie.title)
              setValue('description',cookie.description)
              setValue('format_wa',cookie.format_wa)

              //Reset Form
              reset()
              setError([])
              window.location.reload(); 
              loadingOff()
            }else{
              if(result.errors){
                setError(result.errors)
                console.log(result.errors)
              }else{
                setError([])
              }
              loadingOff()
              setisMsgBox({
                open: true,
                status: 'error',
                title:'Update Setting',
                msg: result.message,
              })
            }
          })
          .then(()=>{
            loadingOff()
          })
          
    }

    const uploadPhoto = (data) => {
      setLoadingUpload(true)
        
      const myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Authorization",cookie.token);

      const formData = new FormData();
      //Setup Photo
      if(photoUpload != null){
        for (var i = 0; i < photoUpload.length ; i++ ) {
          formData.append("files[]", photoUpload[i], photoUpload[i].name)
        }
      }
      const requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: formData,
        redirect: "follow"
      };

      fetch(props.config.hostApi + 'portal/photos/update', requestOptions)
          .then((response) => response.json())
          .then((result) => {
            setLoadingUpload(false)
            if(result.success){
              //setting
              setCookie('photos', result.data.photos);
              setCookie('setting',true)
              //Reset Form
              reset()
              setError([])
              window.location.reload(); 
              setLoadingUpload(false)
            }else{
              if(result.errors){
                setError(result.errors)
              }else{
                setError([])
              }
              setLoadingUpload(false)
              setisMsgBox({
                open: true,
                status: 'error',
                title:'Upload Photo',
                msg: result.message,
              })
            }
          })
          .then(()=>{
            setLoadingUpload(false)
          })
      
         
    }

    const [state, setState] = React.useState({
      isForm:false
    });

    useImperativeHandle(ref, () => ({
      openForm: (config) => {
        setState({ isForm: true})
        console.log(cookie)
        setFilePhotos(cookie.photos)
        setValue('company_name',cookie.company_name)
        setValue('title',cookie.title)
        setValue('description',cookie.description)
        setValue('format_wa',cookie.format_wa)

        setValue('user',cookie.hotspotuser)
        setValue('password',cookie.hotspotpass)

        setTheme(cookie.theme)
        setBgColor(cookie.background_color )
        setBorderColor(cookie.border_color)
        setFormColor(cookie.form_color)
        setTextColor(cookie.text_color)
        setButtonColor(cookie.button_color)
        setButtonTextColor(cookie.button_text_color)
      }
   }),[cookie]);

  const loadingOn = () =>{
    setLoading(true)
  }
  const loadingOff = () =>{
    setLoading(false)
  }
  const [fileObj,setfileObj] = useState([]);
    const [fileArray,setfileArray] = useState([]);
    const [filePhoto,setfilePhoto] = useState(null);

    const uploadMultipleFiles = (e)=>{
      setfileObj([])
      fileObj.push(e)
      var fsize = 0
      for (let i = 0; i < fileObj[0].length; i++) {
          fileArray.push(URL.createObjectURL(fileObj[0][i]))
          fsize = fsize + fileObj[0][i].size
      }
      //Limit Max 5Mb
      if(fsize < 5000000){
          
          setPhotoUpload(e);
          setError([])
      }else{
        setError([{
              "type": "field",
              "msg": "Images file size limit exceeded. Maximum file size in 5 MB",
              "path": "upload_photo",
              "location": "body"
          }])
      }
    }
    const themeChange = (event) => {
      setTheme(event.target.value);
    };
    const deletePhoto = (index) => {
      setPhotoUpload(null);
      setfileArray([])
    }

  React.useEffect(() => {
  },[cookie]);

  return (
    <>
      <MsgBox config={isMsgBox} />
      <Mui.Drawer open={state.isForm} PaperProps={{sx: { width: "90%", maxWidth: "800px" },}}>

      
        <Mui.Toolbar disableGutters style={{padding:'0px 20px', backgroundColor:"#eeeeee"}}> 
              <Mui.Typography
                sx={{ display: 'flex', alignItems: 'center', fontWeight: "bold" }}
                color="text.secondary"
              >
                Settings
              </Mui.Typography>
              <Mui.Box sx={{ flexGrow: 1 }}>&nbsp;</Mui.Box>
              

              <MuiJoy.Button
                variant="solid"
                onClick={()=> setState({isForm:false})}  
                startDecorator={<MuiIcon.Replay/>}
              > Back 
              </MuiJoy.Button>
        </Mui.Toolbar>
        <Mui.DialogContent>
        <MuiJoy.Card sx={{ mb:3 }}>
              <MuiJoy.Box sx={{ mb: 1 }}>
                  <Mui.Typography level="title-md">Secret Key : {cookie.secret}</Mui.Typography>
              </MuiJoy.Box>
            </MuiJoy.Card>
          <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
            <MuiJoy.Card sx={{ mb:3 }}>
              <MuiJoy.Box sx={{ mb: 1 }}>
                  <Mui.Typography level="title-md">Account Detail :</Mui.Typography>
              </MuiJoy.Box>
              <Mui.Divider />
              
              <MuiJoy.Stack
                direction="row"
                spacing={3}
                sx={{ display: { xs: 'flex' }, my: 1 }}
              >
                <MuiJoy.Stack direction="column" spacing={1}>
                  <MuiJoy.AspectRatio
                    ratio="1"
                    maxHeight={200}
                    sx={{ flex: 1, minWidth: 120, borderRadius: '100%' }}
                  >
                    {cookie.company_logo?(
                      <img
                      src={cookie.company_logo}
                      srcSet={cookie.company_logo}
                      loading="lazy"
                      alt=""
                    />
                    ):(
                      <img
                        src={profileIcon}
                        srcSet={profileIcon}
                        loading="lazy"
                        alt=""
                      />
                    )}
                    
                  </MuiJoy.AspectRatio>
                  <MuiFileInput
                        placeholder="UploadLogo" 
                        value={fileLogo} 
                        variant="outlined"
                        size="small"
                        style={{width:'100%', maxWidth:'180px'}}
                        /*getSizeText={(value) => {console.log(value)}}*/
                    
                        InputProps={{
                          startAdornment: <MuiIcon.AttachFile />
                        }}
                        clearIconButtonProps={{
                          children: <MuiIcon.Close fontSize="12px" />
                        }}
                        onChange={(newfile) =>{
                          if(newfile){
                            if(newfile.size < 500000){
                                setFileLogo(newfile)
                                setError([])
                            }else{
                              setError(
                                [{
                                    "type": "field",
                                    "msg": "Logo file size limit exceeded. Maximum file size in 500 KB",
                                    "path": "upload_logo",
                                    "location": "body"
                                }]
                              )
                            }
                          }else{
                            setFileLogo(null)
                            setError([])
                          }
                        }
                      } 
                    />
                    {error.filter(error => error.path === 'upload_logo').length > 0 && <p className="error">{error.filter(error => error.path === 'upload_logo')[0].msg}</p>}
                </MuiJoy.Stack>
                <MuiJoy.Stack spacing={2} sx={{ flexGrow: 1 }}>
                  <MuiJoy.Stack spacing={1}>
                    <MuiJoy.FormControl>
                        <Mui.TextField label="Company Name" size="small" variant="outlined" className="inputField" {...register("company_name")} />
                        {error.filter(error => error.path === 'company_name').length > 0 && <p className="error">{error.filter(error => error.path === 'company_name')[0].msg}</p>}
                    </MuiJoy.FormControl>
                  </MuiJoy.Stack>
                  <MuiJoy.Stack spacing={1}>
                        <Mui.TextField label="Title" size="small" variant="outlined" className="inputField" {...register("title")} />
                        {error.filter(error => error.path === 'title').length > 0 && <p className="error">{error.filter(error => error.path === 'title')[0].msg}</p>}
                  </MuiJoy.Stack>
                  <MuiJoy.Stack spacing={1}>
                        <Mui.FormControl size="small" className="inputField">
                            <Mui.TextField
                                multiline
                                size="small"
                                rows={6}
                                label="Description"
                                {...register("description")}
                              />
                        </Mui.FormControl>
                        {error.filter(error => error.path === 'description').length > 0 && <p className="error">{error.filter(error => error.path === 'description')[0].msg}</p>}
                  </MuiJoy.Stack>
                  <MuiJoy.Stack spacing={1}>
                        <Mui.FormControl size="small" className="inputField">
                            <Mui.TextField
                                multiline
                                size="small"
                                rows={6}
                                label="WA Format"
                                {...register("format_wa")}
                              />
                        </Mui.FormControl>
                        {error.filter(error => error.path === 'format_wa').length > 0 && <p className="error">{error.filter(error => error.path === 'format_wa')[0].msg}</p>}
                  </MuiJoy.Stack>
                </MuiJoy.Stack>
              </MuiJoy.Stack>
              <MuiJoy.CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
                
                <MuiJoy.Box sx={{ mb: 1, padding:'20px 0 10px 0px' }}>
                    <Mui.Typography level="title-md">Hotspot Login :</Mui.Typography>
                </MuiJoy.Box>
                <Mui.Divider sx={{mb:'20px'}} />
                <Mui.Grid container>
                    <Mui.Grid item md={6}>
                        <MuiJoy.FormControl style={{padding:'5px'}}>
                            <Mui.TextField label="User Hotspot" size="small" variant="outlined" className="inputField" {...register("user")} />
                            {error.filter(error => error.path === 'user').length > 0 && <p className="error">{error.filter(error => error.path === 'user')[0].msg}</p>}
                          </MuiJoy.FormControl>
                    </Mui.Grid>
                    <Mui.Grid item md={6}>
                        <MuiJoy.FormControl style={{padding:'5px'}}>
                            <Mui.TextField label="Password Hotspot" size="small" variant="outlined" className="inputField" {...register("password")} />
                            {error.filter(error => error.path === 'password').length > 0 && <p className="error">{error.filter(error => error.path === 'password')[0].msg}</p>}
                          </MuiJoy.FormControl>
                    </Mui.Grid>
                    
                    
                </Mui.Grid>
              
              </MuiJoy.CardOverflow>
              <MuiJoy.CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
                
                <MuiJoy.Box sx={{ mb: 1, padding:'20px 0 10px 0px' }}>
                    <Mui.Typography level="title-md">Theme Setting :</Mui.Typography>
                </MuiJoy.Box>
                <Mui.Divider sx={{mb:'20px'}} />
                <Mui.Grid container>
                    <Mui.Grid item md={12}>
                        <MuiJoy.FormControl>
                              <Mui.InputLabel id="select-theme-label">Captive Portal Theme</Mui.InputLabel>
                              <Mui.Select
                              labelId="select-theme-label"
                              size="small"
                              value={theme}
                              style={{marginBottom: '10px'}}
                              onChange={themeChange}
                            >
                              <Mui.MenuItem value={1}>Theme 1</Mui.MenuItem>
                              <Mui.MenuItem value={2}>Theme 2</Mui.MenuItem>
                            </Mui.Select>
                          </MuiJoy.FormControl>
                    </Mui.Grid>
                    <Mui.Grid item md={4}>
                        <MuiJoy.FormControl>
                              <ColorPicker 
                                label='Background Color'
                                value={bgColor}
                                style={{marginBottom: '10px', width:'100%'}}
                                inputType="input"
                                onChange={(bg)=> setBgColor(bg)} 
                              />
                              {error.filter(error => error.path === 'background_color').length > 0 && <p className="error">{error.filter(error => error.path === 'background_color')[0].msg}</p>}
                          </MuiJoy.FormControl>
                    </Mui.Grid>
                    <Mui.Grid item md={4}>
                        <MuiJoy.FormControl>
                              <ColorPicker 
                                label='Border Color'
                                value={borderColor}
                                style={{marginBottom: '10px'}}
                                inputType="input"
                                onChange={(border)=> setBorderColor(border)} 
                              />
                              {error.filter(error => error.path === 'border_color').length > 0 && <p className="error">{error.filter(error => error.path === 'border_color')[0].msg}</p>}
                          </MuiJoy.FormControl>
                    </Mui.Grid>
                    <Mui.Grid item md={4}>
                        <MuiJoy.FormControl>
                              <ColorPicker 
                                label='Form Color'
                                value={formColor}
                                style={{marginBottom: '10px'}}
                                inputType="input"
                                onChange={(form)=> setFormColor(form)} 
                              />
                          </MuiJoy.FormControl>
                    </Mui.Grid>
                    <Mui.Grid item md={4}>
                        <MuiJoy.FormControl>
                              <ColorPicker 
                                label='Text Color'
                                value={textColor}
                                style={{marginBottom: '10px'}}
                                inputType="input"
                                onChange={(text)=> setTextColor(text)} 
                              />
                          </MuiJoy.FormControl>
                    </Mui.Grid>
                    <Mui.Grid item md={4}>
                        <MuiJoy.FormControl>
                              <ColorPicker 
                                label='Button Color'
                                value={buttonColor}
                                style={{marginBottom: '10px'}}
                                inputType="input"
                                onChange={(button)=> setButtonColor(button)} 
                              />
                          </MuiJoy.FormControl>
                    </Mui.Grid>
                    <Mui.Grid item md={4}>
                        <MuiJoy.FormControl>
                              <ColorPicker 
                                label='Button Text Color'
                                value={buttonTextColor}
                                style={{marginBottom: '10px'}}
                                inputType="input"
                                onChange={(buttonText)=> setButtonTextColor(buttonText)} 
                              />
                          </MuiJoy.FormControl>
                    </Mui.Grid>
                    
                </Mui.Grid>
              
              </MuiJoy.CardOverflow>
              <MuiJoy.CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
                <MuiJoy.CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
                  <MuiJoy.Button
                    type="submit"
                    variant="solid" 
                    loading={loading} 
                    sx={{ margin: '0px 3px'}}
                    startDecorator={<MuiIcon.Save />}
                    loadingPosition="start"
                    > Update
                  </MuiJoy.Button>
                  <MuiJoy.Button
                    type="button"
                    variant="solid" 
                    sx={{ margin: '0px 3px'}}
                    startDecorator={<MuiIcon.RemoveRedEye />}
                    loadingPosition="start"
                    onClick={()=>{
                      window.open('https://portal.netmaster.id/?s='+cookie.secret+'&h=1','_blank');
                    }}
                    > Preview
                  </MuiJoy.Button>
                </MuiJoy.CardActions>
              </MuiJoy.CardOverflow>
            </MuiJoy.Card>
          </form>
          <form ref={chpformRef} onSubmit={handleSubmit(uploadPhoto)}>
            <MuiJoy.Card sx={{mb:3}}>
              <MuiJoy.Box sx={{ mb: 1 }}>
                <MuiJoy.Typography level="title-md">Banner Photo <span style={{fontSize:'14px'}}>( Max. 4 Photos )</span></MuiJoy.Typography>
              </MuiJoy.Box>
              <Mui.Divider />
              <MuiJoy.Stack spacing={1} sx={{ flexGrow: 1, mb:2 }}>
                <Mui.Grid container>
                  <Mui.Grid item xs={9} className="innerForm">
                    <MuiFileInput
                        placeholder="UploadPhoto" 
                        value={photoUpload}
                        sx={{border:'1px solid #FF9B00', borderRadius:'5px', width:'100%'}}
                        variant="outlined"
                        size="small"
                        InputProps={{
                          startAdornment: <MuiIcon.AttachFile />
                        }}
                        clearIconButtonProps={{
                          children: <MuiIcon.Close fontSize="12px" />,
                          onClick:()=>{
                            deletePhoto()
                          }
                        }}
                        onChange={(newfile) => {   
                          uploadMultipleFiles(newfile)
                        }} 
                        multiple
                    />
                    {error.filter(error => error.path === 'upload_photo').length > 0 && <p className="error">{error.filter(error => error.path === 'upload_photo')[0].msg}</p>}
                  </Mui.Grid>
                  <Mui.Grid item xs={3} className="innerForm">
                      <MuiJoy.Button
                            type="submit"
                            variant="solid" 
                            size="lg"
                            loading={loadingUpload} 
                            sx={{ margin: '0px 3px', width:'100%'}}
                            startDecorator={<MuiIcon.ViewCarousel />}
                            loadingPosition="start"
                            > Upload 
                          </MuiJoy.Button>
                  </Mui.Grid>
                  <Mui.Grid item xs={12} className="innerForm">
                          

                          <Mui.ImageList sx={{ width: '100%', height: 450 }}>
                            
                          {filePhotos.map((url,key) => (
                              <Mui.ImageListItem key={key}>
                                <img
                                    src={url}
                                  />
                              </Mui.ImageListItem>
                            ))}
                          </Mui.ImageList>
                  </Mui.Grid> 
                </Mui.Grid>  
              </MuiJoy.Stack>

            </MuiJoy.Card>
          
          </form>
        </Mui.DialogContent>
      </Mui.Drawer>  
    </>
  );
});

export default DrawerSettings;
