import React,{ useRef,useState } from 'react';
import { useCookies } from 'react-cookie';
import * as Mui  from '@mui/material';
import * as MuiIcon from '@mui/icons-material';
import * as MuiJoy from '@mui/joy';
import exportFromJSON from 'export-from-json';
import Header from '../lib/Header';
import axios from 'axios';
import GetStatus from '../lib/GetStatus';
import MsgBox from '../lib/MsgBox';
import Datex from '@drumtj/datex';
import { useForm, SubmitHandler } from "react-hook-form"

const Dashboard = (props) => {
  var dx = new Datex();
  const [cookies] = useCookies(['bas_']);
  const [contentLoading,setContentLoading] = useState(false)
  const [exportXls,setExportXls] = useState(false)
  const [data,setData] = useState([]);
  const [isMsgBox, setisMsgBox] = React.useState({})
  const addRef = useRef();
  const formRef = useRef();
  const [page,setPage] = React.useState()
  var obj;

  
  const changePage = (event,value) => {
    setPage(value);
  };
  const [pagination,setPagination] = React.useState([])
  const [filterEnable, setFilterEnable] = useState(false)
  const [filter,setFilter] = React.useState({
    search: '',
    perPage: 10,
    project_type_id: '',
    status: ''
  })

  const { register, setValue, handleSubmit,reset } = useForm()
  const onSubmit = (data) => {
    setPage(1)
    setFilter({
      search: data.search,
      perPage: data.perPage,
      project_type_id: '',
      status: ''

    })
}
const getData = () => {
  setContentLoading(true)
  
  const myHeaders = new Headers();
  myHeaders.append("Authorization",cookies.token);

  const urlencoded = new URLSearchParams();
  urlencoded.append("search", filter.search);
  urlencoded.append("perPage", filter.perPage);
  urlencoded.append("status", filter.status);
  urlencoded.append("page", page);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    redirect: "follow",
    body: urlencoded
  };

  fetch(props.config.hostApi + '/portal/users/list', requestOptions)
    .then((response) => response.json())
    .then((result) => {
      if(result.success){
        setData(result.data)
        setPagination(result.pagination)
        setContentLoading(false)
      }else{
        //setError(obj.errors)
        setContentLoading(false)
        setisMsgBox({
          open: true,
          status: 'error',
          title:'Project Monitoring',
          msg: result.message,
        })
      }
    })
    .then(()=>{
      setContentLoading(false)
    })
    
}

const exportXlsReport = () => {
  setExportXls(true)
  const myHeaders = new Headers();
    myHeaders.append("Authorization",cookies.token);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    const urlencoded = new URLSearchParams(new FormData());
    urlencoded.append("search", filter.search);
    urlencoded.append("status", filter.status);
    
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow"
    };


    fetch(props.config.hostApi + 'portal/users/export', requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if(result.success){
            const data = result.data
            const fileName = 'Whatsapp-Data';
            const exportType = 'xls';
            exportFromJSON({ data, fileName, exportType });
          }else{
            console.log('not success') 
          }
        })
        .then((error)=>{
          setExportXls(false)
        })
}
React.useEffect(() => {
  getData()
}, [filter,page]); 

  return (
    <div>

      <MsgBox config={isMsgBox} />

      <Header config={props.config} />
      <Mui.Grid className='topBar' container style={{padding:'0px 20px', backgroundColor:"#eeeeee"}}>
        <Mui.Grid item md={12}>
          <Mui.Toolbar disableGutters>
              <Mui.Breadcrumbs aria-label="breadcrumb">
                  <Mui.Link
                    underline="none"
                    sx={{ display: 'flex', alignItems: 'center' }}
                    color="inherit"
                    href="/"
                  >
                    <MuiIcon.Home sx={{ mr: 0.5 }} fontSize="inherit" />
                    Home
                  </Mui.Link>
                  
                  <Mui.Typography
                    sx={{ display: 'flex', alignItems: 'center' }}
                    color="text.primary"
                  >
                    Dashboard
                  </Mui.Typography>
              </Mui.Breadcrumbs>

              <Mui.Box sx={{ flexGrow: 1 }}>&nbsp;</Mui.Box>
              <MuiJoy.Button
                type="button"
                variant="solid" 
                style={{ background:'#0F7C41', margin:'0px 3px', padding: '0px 15px', borderRadius:'25px', fontSize: '12px'}} 
                onClick={()=>{
                  exportXlsReport()
                }}
                > &raquo; Export to Ms Excel
                  {exportXls ? <MuiJoy.LinearProgress
                    variant="solid"
                    color="success"
                    value={40}
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      right: 0,
                      borderRadius: '25px',
                      margin: '0px 10px'
                    }}
                  /> : '' }
              </MuiJoy.Button>
              <MuiJoy.Button
                  type="button"
                  variant="solid" 
                  style={{ background:'#555555', margin:'0px 3px', padding: '0px 15px', borderRadius:'25px', fontSize: '12px'}} 
                  onClick={ ()=> {
                    if(filterEnable)
                      setFilterEnable(false)
                    else  
                      setFilterEnable(true)
                  }}
                  >  <MuiIcon.ManageSearch /> &nbsp; Filter
                   
                </MuiJoy.Button>
          </Mui.Toolbar>
        </Mui.Grid>
      </Mui.Grid>
      {(filterEnable) ? (
        <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
          <Mui.Grid container 
              style={{
              backgroundColor:'#f8f8f8',
              padding:'10px 20px 10px 20px'
            }}>
              <Mui.Box sx={{ flexGrow: 1, margin: '5px' }}>
                  <Mui.TextField label="Keyword" size="small" variant="outlined" className="inputField"  {...register("search")} />
              </Mui.Box>
              <Mui.Box sx={{minWidth:'100px', margin:'5px'}}>
                  <Mui.FormControl size="small" className="inputField">
                      <Mui.TextField
                          select
                          size="small"
                          label="PerPage"
                          {...register("perPage")}
                      >
                          <Mui.MenuItem key={0} value={10}>10 Orders</Mui.MenuItem>
                          <Mui.MenuItem key={1} value={25}>25 Orders</Mui.MenuItem>
                          <Mui.MenuItem key={2} value={50}>50 Orders</Mui.MenuItem>
                          <Mui.MenuItem key={3} value={100}>100 Orders</Mui.MenuItem>
                      </Mui.TextField>
                  </Mui.FormControl>
              </Mui.Box>
              <Mui.Box sx={{margin: '7px 5px'}}>
                    <MuiJoy.Button
                      type="submit"
                      variant="solid" 
                      loading={contentLoading} 
                      sx={{ margin: '0px 3px'}}
                      startDecorator={<MuiIcon.NavigateNext />}
                      loadingPosition="start"
                      > Show
                    </MuiJoy.Button>
              </Mui.Box>
          </Mui.Grid>
        </form>
        ):('')}

      <Mui.Grid container spacing={2}>
      {(contentLoading) ? (
                <Mui.Grid item xs={12} style={{margin:'20px'}}>
                    <h2>Please wait... </h2>
                    <Mui.Box sx={{ width: '100%' }}>
                      <Mui.LinearProgress />
                    </Mui.Box>
                </Mui.Grid>
          ) : (
              <>
              
              <Mui.Grid item xs={12}>
                <Mui.Box style={{padding:'20px'}}>
                      <Mui.Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <Mui.TableHead>
                            <Mui.TableRow>
                                <Mui.TableCell>Date</Mui.TableCell>
                                <Mui.TableCell>Name</Mui.TableCell>
                                <Mui.TableCell>WhatsApp Number</Mui.TableCell>
                                <Mui.TableCell align='center'>OTP Code</Mui.TableCell>
                                <Mui.TableCell align='center'>Status</Mui.TableCell>
                            </Mui.TableRow>
                        </Mui.TableHead>
                        <Mui.TableBody>
                        { data.map((_data,key) => (
                            <Mui.TableRow
                            key={key}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                
                                <Mui.TableCell>
                                  {/*new Date(_data.login_date).toLocaleString('en-US',{ day: 'numeric', month: 'numeric', year: 'numeric', hour12: false, hour: '2-digit', minute: '2-digit', second: '2-digit'})*/}
                                  { new Datex(_data.login_date).format("YYYY-MM-DD hh:mm:ss")}
                                  </Mui.TableCell>
                                <Mui.TableCell>{_data.name}</Mui.TableCell>
                                <Mui.TableCell>{_data.wa_number}</Mui.TableCell>
                                <Mui.TableCell align='center'>{_data.otp}</Mui.TableCell>
                                <Mui.TableCell align='center'>{_data.status_info}</Mui.TableCell>
                            </Mui.TableRow>
                                        )) }
                        </Mui.TableBody>
                    </Mui.Table>
                      
                  </Mui.Box>
              </Mui.Grid>
              <Mui.Grid item xs={12} >
                    <Mui.Pagination 
                        sx={{ 
                          '& > .MuiPagination-ul': {
                            justifyContent: 'center',
                          },
                          p:2,
                        }} count={pagination.totalPages} page={page} onChange={changePage} color="primary" />
                </Mui.Grid>
              </>
          )}
    </Mui.Grid>
    </div>
  );
}

export default Dashboard;



